import React from 'react';
import './styles.scss';
import { Section } from '../Section';

export const PrivacyPolicy = () => {
  return (
    <Section id="policy">
      <div className="policy__wrapper">
        <h1 className="section__title--primary">Privacy Policy</h1>
        <div className="policy__text">
          <div>
            <span>
              <p>
                Your privacy is important to us. It is Girls to Code's policy to
                respect your privacy regarding any information we may collect
                from You across our website girlstocode.com, and other sites we
                own and operate.
              </p>
              <p>
                We use Your Personal data to provide and improve the Service. By
                using the Service, You agree to the collection and use of
                information in accordance with this Privacy Policy.
              </p>
              <p>
                We collect the e-mail addresses of those who subscribe to Girls
                to Code via our website or communicate with us via e-mail.
              </p>
              <ul>
                <li>
                  We may use the e-mail addresses we collect to: Provide,
                  maintain and improve our Site
                </li>
                <li>
                  Send You information on Girls to Code news, e.g. new events,
                  meetings, articles, and support and administrative messages
                </li>
                <li>
                  Respond to Your emails, submissions, comments, questions and
                  requests; request feedback or support, and otherwise contact
                  You about Your use of the Site
                </li>
                <li>
                  Carry out any other purpose described to You at the time the
                  information was collected
                </li>
              </ul>
              <p>
                Any Personal data that you provide to Girls to Code will be
                protected in a commercially reasonable manner. We don’t share
                any personally identifying information publicly or with
                third-parties, except when required to by law.
              </p>
              <p>
                You may request internal deletion of Your Personal data at any
                time by contacting Girls to Code.
              </p>
              <p>
                Your continued use of our website will be regarded as acceptance
                of our practices around Privacy and Personal data. If You have
                any questions about how we handle Personal data, feel free to
                contact us via e-mail{' '}
                <a
                  href="mailto:contact@girlstocode.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact@girlstocode.com
                </a>
                .
              </p>
            </span>
          </div>
          <div>
            <span>
              <h3>
                <strong>Cookies</strong>
              </h3>
              <p>
                This cookie policy is an important supplement of the Girls to
                Code Privacy Policy. The website uses cookies, i.e. small text
                files saved in Your devices. Cookies contain personalised
                information about the way of using the Website. Cookies help us
                to customise the Website so that it is much more friendly for
                You. In addition, based on cookies, we create appropriate
                statistics (the most common login locations, the most popular
                functions), from which we will later draw helpful conclusions.
                It allows us to make Girls to Code website even better. It is
                worth noting that the storage of cookies does not result in
                configuration changes in the telecommunications terminal
                equipment of Your website and the software installed in this
                equipment. Moreover, You can specify the conditions for storing
                or accessing cookies using software or service configuration
                settings.
              </p>
              <p>
                In many cases, the software used to browse websites (a web
                browser) allows for the storing of cookies on Your terminal by
                default. You may change settings related to cookies at any time.
                These settings can be changed, especially in such a way that the
                automatic support for cookies will be blocked on the browser or
                there will be information on their use on the device every time.
                Details on the possibilities and methods of managing cookie
                files are available in the software settings (of the web
                browser).{' '}
              </p>
              <p>All recipients of cookies are protected by law.</p>
            </span>
          </div>
        </div>
      </div>
    </Section>
  );
};
